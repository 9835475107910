import { template as template_3b5882318bb54f2eb9937a6fba4c402b } from "@ember/template-compiler";
const SidebarSectionMessage = template_3b5882318bb54f2eb9937a6fba4c402b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
