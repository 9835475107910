import { template as template_280d74fe48fb4dfcb9d1fb89960b137f } from "@ember/template-compiler";
const FKText = template_280d74fe48fb4dfcb9d1fb89960b137f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
