import { template as template_810cd9248e5b48f5a9f02d33953bf1cb } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_810cd9248e5b48f5a9f02d33953bf1cb(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
